<template>
  <div class="auth-wrapper auth-v1 warpperContent">
    <v-card class="content">
      <v-card-text>
        <!-- Header -->
        <div class="mb-5 text-end">
          <img src="../../../assets/images/logos/logo.jpg" alt="logo" class="sizeImage" />
          <h2>Case</h2>
          <h4>123 facas dsasds dsa</h4>
          <h4>เลขที่ผู้เสียภาษี 123456789098765</h4>
        </div>
        <div class="contentHeaderNumber">
          <div class="text-content">
            <div class="d-flex">
              <div class="text-center conten-text-left">
                <h1>Receive Inventory</h1>
                <h2>ใบชำระเงิน</h2>
              </div>
              <div class="content-text-right">
                <div class="border-bottom-text">
                  <h4>ต้นฉบับ / Original</h4>
                </div>
                <div class="d-flex justify-center text-position-header">
                  <h2>{{ this.$store.state.numberBill }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Header -->

        <!-- รายละเอียดผู้ขาย -->
        <v-row class="reset-margin">
          <!-- Content Left -->
          <v-col md="6" sm="6" cols="12" class="mt-5 wrapper-details-content-left">
            <v-row class="reset-margin">
              <!-- ผู้ขาย -->
              <v-col sm="4" md="4" cols="12" class="reset-padding ">
                <h3>ผู้ขาย</h3>
                <p class="mb-0 fontsizeCustom">Supllier</p>
              </v-col>
              <v-col sm="8" md="8" cols="12" class="reset-padding ">
                <h3>{{ this.$store.state.nameSupplier }}</h3>
              </v-col>

              <!-- เลขที่ผู้เสียภาษี -->
              <v-col sm="4" md="4" cols="12" class="mt-2 reset-padding">
                <h3>เลขที่ผู้เสียภาษี</h3>
                <p class="mb-0 fontsizeCustom">Tax ID</p>
              </v-col>
              <v-col sm="8" md="8" cols="12" class="mt-2 reset-padding">
                <h3>{{ this.$store.state.taxId }}</h3>
              </v-col>

              <!-- ที่อยู่ -->
              <v-col sm="4" md="4" cols="12" class="mt-2 reset-padding">
                <h3>ที่อยู่</h3>
                <p class="mb-0 fontsizeCustom">Address</p>
              </v-col>
              <v-col sm="8" md="8" cols="12" class="mt-2 reset-padding">
                <h3>{{ this.$store.state.addressSupplier }}</h3>
              </v-col>
            </v-row>
          </v-col>
          <!-- End Content Left -->

          <!-- Content Right -->
          <v-col md="6" sm="6" cols="12" class="mt-5 wrapper-details-content-right">
            <v-row class="reset-margin">
              <v-col sm="6" md="6" cols="12" class="reset-padding borderRight">
                <v-row class="reset-margin">
                  <!-- วันที่ -->
                  <v-col sm="6" md="6" cols="12" class="reset-padding">
                    <h4>วันที่</h4>
                    <p class="mb-0 fontsizeCustom">Issue Date</p>
                  </v-col>
                  <v-col sm="6" md="6" cols="12" class="reset-padding">
                    <h4>{{ this.$store.state.issueDate }}</h4>
                  </v-col>
                  <!-- ใบกำกับภาษี -->
                  <v-col sm="6" md="6" cols="12" class="reset-padding">
                    <h4>ใบกำกับภาษี</h4>
                    <p class="mb-0 fontsizeCustom">Tax Invoice</p>
                  </v-col>
                  <v-col sm="6" md="6" cols="12" class="reset-padding">
                    <h4>{{ this.$store.state.taxInvoice }}</h4>
                  </v-col>
                  <!-- วันที่ใบกำกับ -->
                  <v-col sm="6" md="6" cols="12" class="reset-padding">
                    <h4>วันที่ใบกำกับ</h4>
                    <p class="mb-0 fontsizeCustom">Tax Inv Date</p>
                  </v-col>
                  <v-col sm="6" md="6" cols="12" class="reset-padding">
                    <h4>{{ this.$store.state.taxInvDate }}</h4>
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="6" md="6" cols="12" class="reset-padding transform-content">
                <v-row class="reset-margin">
                  <!-- ผู้จัดทำ -->
                  <v-col sm="6" md="6" cols="12" class="reset-padding">
                    <h4>ผู้จัดทำ</h4>
                    <p class="mb-0 fontsizeCustom">Prepared By</p>
                  </v-col>
                  <v-col sm="6" md="6" cols="12" class="reset-padding">
                    <h4>{{ this.$store.state.PreparedBy }}</h4>
                  </v-col>
                  <!-- ใบสั่งซื้อ -->
                  <v-col sm="6" md="6" cols="12" class="reset-padding">
                    <h4>ใบสั่งซื้อ</h4>
                    <p class="mb-0 fontsizeCustom">Purchase Order</p>
                  </v-col>
                  <v-col sm="6" md="6" cols="12" class="reset-padding">
                    <h5>{{ this.$store.state.PurchaseOrder }}</h5>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="punctuate"></div>
            <div class="mt-2 d-flex">
              <div>
                <h4>ชื่อโปรเจค</h4>
                <p class="mb-0 fontsizeCustom">Project Name</p>
              </div>
              <h4 class="ms-7">{{ this.$store.state.projectName }}</h4>
            </div>
          </v-col>
          <!-- End Content Right -->
        </v-row>
        <!-- จบรายละเอียดผู้ขาย -->

        <!-- รายละเอียดสินค้า -->
        <v-data-table
          :headers="headers"
          :items="desription"
          hide-default-footer
          class="elevation-1 mt-5 mb-5"
        ></v-data-table>
        <!-- สิ้นสุดรายละเอียดสินค้า -->

        <!-- หมายเหตุ และราคารวม -->
        <v-row>
          <v-col md="6" sm="6" cols="12">
            <div class="d-flex justify-space-between align-center mb-2 totalPriceThai">
              <p class="mb-0">จำนวนเงิน <br /><span>Amount</span></p>
              <h3>{{ this.$store.state.princeThia }}</h3>
            </div>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <!-- รวมเป็นเงิน -->
            <div class="d-flex justify-space-between mb-2">
              <p class="mb-0">รวมเป็นเงิน <br /><span>Subtotal</span></p>
              <p class="mb-0">{{ this.$store.state.Subtotal }}</p>
            </div>
            <hr class="mt-2 mb-2" />
            <!-- หักส่วนลดพิเศษ -->
            <div class="d-flex justify-space-between mb-2">
              <p class="mb-0">หักส่วนลดพิเศษ <br /><span>Special Discount</span></p>
              <p class="mb-0">{{ this.$store.state.SpecialDiscount }}</p>
            </div>
            <hr class="mt-2 mb-2" />
            <!-- ยอดรวมหลังหักส่วนลด -->
            <div class="d-flex justify-space-between mb-2">
              <p class="mb-0">ยอดรวมหลังหักส่วนลด <br /><span>After Discount</span></p>
              <p class="mb-0">{{ this.$store.state.AfterDiscount }}</p>
            </div>
            <hr class="mt-2 mb-2" />
            <!-- จำนวนภาษีมูลค่าเพิ่ม 7 % -->
            <div class="d-flex justify-space-between mb-2">
              <p class="mb-0">จำนวนภาษีมูลค่าเพิ่ม 7 % <br /><span>Value Add Tax</span></p>
              <p class="mb-0">{{ this.$store.state.valueAddTax }}</p>
            </div>
            <!-- จำนวนเงินรวมทั้งสิ้น -->
            <div class="d-flex justify-space-between mb-2 totalPrice">
              <p class="mb-0">จำนวนเงินรวมทั้งสิ้น <br /><span>Total</span></p>
              <p class="mb-0">{{ this.$store.state.total }}</p>
            </div>
          </v-col>
        </v-row>
        <!-- สิ้นสุดหมายเหตุ และราคารวม -->
        <v-row class="reset-margin border-content">
          <v-col md="4" sm="4" cols="12" class="borderRight wrapper-content-bottom">
            <div class="wrapper-content-bottom">
              <div class="borderDash"></div>
              <p class="mb-0">ผู้ตรวจสอบ / Approver</p>
              <div class="d-flex">
                <p class="mb-0">วันที่ / Date</p>
              </div>
            </div>
          </v-col>
          <v-col md="4" sm="4" cols="12" class="d-flex justify-center align-center borderRight">
            <h2>ตราประทับบริษัท</h2>
          </v-col>
          <v-col md="4" sm="4" cols="12" class="wrapper-content-bottom">
            <div class="wrapper-content-bottom">
              <div class="borderDash"></div>
              <p class="mb-0">ผู้มีอำนาจลงนาม / Authorized Signature</p>
              <div class="d-flex">
                <p class="mb-0">วันที่ / Date</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: 'เลขที่',
          align: 'start',
          value: 'no',
        },
        { text: 'รายการ', value: 'description' },
        { text: 'จำนวน', value: 'quantity' },
        { text: 'ราคา/หน่วย', value: 'unitPrice' },
        { text: 'ส่วนลด', value: 'discount' },
        { text: 'จำนวนเงิน', value: 'amount' },
      ],
      desription: [
        {
          no: '1',
          description: 'กระเบื้องเซรามิก',
          quantity: 1000,
          unitPrice: 80,
          discount: 0,
          amount: 80000,
        },
        {
          no: '2',
          description: 'พื้นไม้เทียม',
          quantity: 50,
          unitPrice: 1200,
          discount: 0,
          amount: 60000,
        },
      ],
    }
  },
}
</script>

<style scoped>
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

* {
  box-sizing: border-box;
}
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
li {
  list-style-type: decimal;
}
img {
  width: 2rem;
  height: 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
}
.fontsizeCustom {
  font-size: 12px !important;
}
.reset-margin {
  margin: 0 !important;
}
.reset-padding {
  padding: 0 !important;
}
.sizeImage {
  width: 50px;
  height: 50px;
}
.borderRight {
  border-right: 2px solid black;
}
.transform-content {
  transform: translateX(10px);
}
.warpperContent {
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
}
.borderDash {
  border-bottom: 2px dashed black;
}
.contentHeaderNumber {
  display: flex;
  justify-content: end;
}
.totalPriceThai {
  background-color: #f7f7ff;
  padding: 5px 10px;
  border-radius: 5px;
}
.wrapper-details-content-left {
  border: 2px solid black;
}
.wrapper-details-content-right {
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  border-top: 2px solid black;
}
.punctuate {
  border-top: 2px solid black;
  margin-top: 0.5rem;
}
.text-content {
  width: 50%;
}
.conten-text-left {
  background-color: black;
  border-top-left-radius: 10px;
  padding: 10px;
  width: 60%;
  max-width: 18.75rem;
}
.conten-text-left h1 {
  color: white !important;
}
.conten-text-left h2 {
  color: white !important;
}
.content-text-right {
  border: 2px solid black;
  width: 40%;
  max-width: 14.75rem;
}
.border-bottom-text {
  border-bottom: 2px solid black;
  text-align: center;
}
.text-position-header {
  transform: translateY(50%);
}
.content {
  width: 70%;
}
.transform_text {
  transform: translateY(100%);
}
.totalPrice {
  background-color: #8a8d93;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}
.text_customer {
  font-size: 20px;
}
.border-content {
  border: 2px solid black;
}
.wrapper-content-bottom {
  display: grid;
  justify-content: center;
  align-content: flex-end;
  height: 7.5rem;
}
@media screen and (max-width: 600px) {
  .warpperContent {
    padding: 2rem 2rem;
  }
}
</style>
